//引入VueRouter
import VueRouter from 'vue-router'
//引入 组件
import Index from '../pages/jindie/index.vue'

//创建router实例对象，去管理一组一组的路由规则
const router = new VueRouter({
	routes:[
        {
            path:'/',
            component:Index,
        }

	]
})


//暴露router
export default router