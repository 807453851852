<template>
  <div>
    <header class="header-nave">
      <div :class="scrollTop?'header-nave-no-top':'header-nave-top'">
        <div class="w1440">
          <div style="width:18%;display:inline-block;float:left"  @mouseover="hoverLogo" @mouseleave="resetLogo">
            <img :src=logoSrc alt="" style="height:70px;">
          </div>
          <div style="width:60%;display:inline-block;float:left">
            <ul>
              <li>产品</li>
              <li>解决方案</li>
              <li>行业标杆</li>
              <li>支持与社区</li>
              <li>应用市场</li>
              <li>伙伴与生态</li>
              <li>关于零差云</li>
            </ul>
          </div>
          <div style="width:12;display:inline-block;float:left;line-height:75px;">
            <i class="el-icon-phone-outline" style="font-size:20px;" />
            <span style="font-size:16px;line-height:16px;margin-left:10px;font-weight:400;">0592-5065360</span>
          </div>
          <div class="login-button" @click="handleLogin">
            登录
          </div>
        </div>
      </div>
    </header>

    <div class="product_hr_banner">
      <img src="../../assets/head-bg.png" alt="" height="405px">
      <div class="content">
        <div class="w1440">
          <h1>零差云产品</h1>
          <div class="text">零差云产品体系支撑您的各类业务上云, 助您实现数字化转型</div>
          <div class="link">立即查询</div>
        </div>
      </div>
    </div>

    <br><br><br><br>
    <div class="w1440">
      <div class="sidebar">
        <ul>
          <li @click="jumpInto('产品')">
            产品
          </li>
          <li @click="jumpInto('行业案例')"><!--  class="active" -->
            行业案例
          </li>
        </ul>
      </div>
      <div class="list">
        <ul>
          <li v-for="title,index0 in Object.keys(items)" :key="index0">
            <div :id="title" class="title">
              {{ title }}
            </div>
            <div v-for="subtitle,index1 in Object.keys(items[title])" :key="index1">
              <div v-if="subtitle!==''" class="subtitle">
                <a>
                  {{ subtitle }} >
                </a>
              </div>

              <ul style="transform: translateX(-20px);display:flex;flex-wrap:wrap">
                <li v-for="item,index2 in items[title][subtitle]" :key="index2" class="card-box" @click="openSystem(item)">
                  <img :src="item.icon" alt="logo">
                  <div class="main">
                    <div class="title">
                      <span>{{ item['title'] }}</span>
                      <div v-if="item['title'] === '多工序制造企业' | item['title'] === '项目制企业'" class="hot">web系统</div>
                      <div v-if="item['title'] === '多工序制造企业' | item['title'] === '项目制企业'" class="hot">软件</div>
                      <div v-else-if="subtitle === '财务总账模块' | subtitle === '便捷工具箱'" class="unavail">未开通</div>
                      <div v-else-if="title === '行业案例'" class="custom">定制</div>
                      <div v-else class="hot">热门</div>
                    </div>
                    <div class="content">
                      {{ item['content'] }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

    </div>

    <footer style="width:100%;height:100px;background-color:#1D2C43;clear:both;color:rgba(255, 255, 255, 0.5)">
      <div style="margin: 0 auto;display: flex;justify-content: center;align-items: center;line-height:100px">
        <span>苏州零差信息技术有限公司</span>
        <span style="margin-left:20px">闽ICP备2022009847号-1</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // scrollTop: false,  // 用于判断页面是否滚动
      logoSrc: require('@/assets/logo_white.png'),  // 初始 logo 图片
      topLogoSrc: require('@/assets/logo.png'), // 滚动后 logo 图片
      scrolledLogoSrc: require('@/assets/logo_white.png'),  // 滚动后显示的 logo
      hoverLogoSrc: require('@/assets/logo.png'),  // 鼠标悬停时显示的 logo
      scrollTop: 0,
      items: {
        '产品': {
          '供应链模块': [
            {
              title: '多工序制造企业',
              content: '提供多工序成本核算、业务单据生成服务',
              src: 'https://zzy.lingcha.cn/',
              icon: require('@/assets/homeicon/people.png')
            },
            {
              title: '项目制企业',
              content: '提供项目管理、工时管理、成本核算、管理报表分析、凭证生成的全链条服务',
              src: 'https://xmz.lingcha.cn/',
              icon: require('@/assets/homeicon/people.png')
            }
          ],
          '研发管理模块': [
            {
              title: '研发生命周期管理',
              content: '以项目为核心， 全流程一体化管控',
              src: 'https://www.lingcha.cn/project/',
              icon: require('@/assets/homeicon/flow.png')
            },
            {
              title: '研发工时管理',
              content: '实现工时从申报到审批的全流程管理',
              src: 'https://www.lingcha.cn/workinghour/',
              icon: require('@/assets/homeicon/workinghour.png')
            },
            {
              title: '研发项目成本核算',
              content: '根据业务数据，自动实现料、工、费金额的归集与分摊',
              src: 'https://www.lingcha.cn/democlient/',
              icon: require('@/assets/homeicon/asset.png')
            }
          ],
          '成本核算模块': [
            {
              title: '传统制造业',
              content: '成本核算',
              src: 'https://www.lingcha.cn/costaccount/',
              icon: require('@/assets/homeicon/flow.png')
            }
          ],
          '财务总账模块': [
            { title: '序时账生成器', content: '根据业务单据自动生成序时账', icon: require('@/assets/homeicon/genera.png') },
            { title: '报表生成器', content: '根据序时账生成报表', icon: require('@/assets/homeicon/genera.png') }
          ],
          '便捷工具箱': [
            { title: 'PDF电子表格提取', content: '批量提取PDF电子表格', icon: require('@/assets/homeicon/box.png') },
            { title: '数据格式转化', content: '业务数据多列转单列-复核全流程管理', icon: require('@/assets/homeicon/box.png') }
          ] },
        '行业案例': { '': [
          {
            title: '医疗器械企业',
            content: '深度了解企业需求，进行定制化开发，多维度呈现管理报表',
            src: 'https://yl.lingcha.cn',
            icon: require('@/assets/homeicon/line.png')
          },
          {
            title: '生物科研企业',
            content: '深度了解企业需求，进行定制化开发，灵活配置多种工费分摊方式',
            src: 'https://sw.lingcha.cn',
            icon: require('@/assets/homeicon/line.png')
          }
        ] }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrolling)
  },
  methods: {
    scrolling() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
                    document.body.scrollTop
      this.scrollTop = scrollTop
      if (!this.scrollTop) {
          this.logoSrc = this.scrolledLogoSrc;  // 置顶logo
        } else {
          this.logoSrc = this.topLogoSrc;  // 切换到滚动后的 logo
        }
    },
    hoverLogo() {
      this.logoSrc = this.hoverLogoSrc;  // 鼠标悬停时切换的 logo
    },
    resetLogo() {
      // 悬停后恢复 logo 状态，依据当前是否滚动决定显示哪个 logo
      if (!this.scrollTop) {
          this.logoSrc = this.scrolledLogoSrc;  // 置顶logo
        } else {
          this.logoSrc = this.topLogoSrc;  // 切换到滚动后的 logo
        }
    },
    jumpInto(name) {
      document.getElementById(name).scrollIntoView({ behavior: 'smooth', block: 'center' })
    },
    handleLogin(){
      window.open('https://www.lingcha.cn/test_show/', '_blank')
    },
    openSystem(item) {
      if (item.src) {
        // const newWin = window.open('', '_blank')
        // const tmpSrc = item.src
        // newWin.document.write(`<body scroll="no" style="margin: 0px;padding: 0px;border:0px;overflow:hidden;"><iframe style="margin: 0px;padding: 0px;border: 0px;width:100%;height:100%" src="${tmpSrc}"></iframe></body>`)
        window.open(item.src, '_blank')
      }
    }
  }
}
</script>

<style lang="scss">
/* 默认的 logo 样式 */
.logo {
  height: 70px;
  transition: all 0.3s ease; /* 添加过渡效果，使切换更平滑 */
}

/* 鼠标悬停时切换 logo */
.logo-container:hover .logo {
  content: url('../../assets/logo.png'); /* 替换为鼠标悬停时的 logo 图片 */
}

body {
  line-height: 1.5;
  
}
ul {
    list-style-type:none;
    margin:0;padding:0;
}
.header-nave {
    .w1440 {
      width:1440px;
      margin:0 auto;
          
    }
    .header-nave-top {
        width: 100%;
        height: 75px;
        position: fixed;
        top: 0px;
        left: 0;
        color: #fff;
        padding-top: 30px;
        z-index: 9;
        &:hover {
          background-color: #fff;
          height: 105px;
          color: #252B3A;
          .login-button {
            background-image: linear-gradient(135deg, #0068dd, #00aeee);
            color: #fff;
          }
        }
        .login-button {
              float:left;
              margin-top:20px;
              margin-left:80px;
              background-color:#fff;
              padding:5px 13px;
              color: #252B3A;
              border-radius: 3px;
              cursor: pointer;
            }
        
    }


    .header-nave-no-top {
      width: 100%;
      height: 75px;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      box-shadow: 0 1px 20px rgb(54 54 54 / 40%);
      z-index: 9;
      .login-button {
              float:left;
              margin-top:20px;
              margin-left:80px;
              background-image: linear-gradient(135deg, #0068dd, #00aeee);
              padding:5px 13px;
              color: #fff;
              border-radius: 3px;
              cursor: pointer;
            }
      
    }

    ul {
        li {
          display: inline-block;
          padding: 0 17px;
          line-height: 75px;
          position: relative;
          &::before {
            content: '';
            width: 0;
            height: 2px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            background-color: #0086F9;
            transform: translateX(-50%);
            transition: all 0.2s;
          }
          &:hover::before {
            content: '';
            width: 80%;
            height: 2px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            background-color: #0086F9;
            transform: translateX(-50%);
            transition: all 0.2s;
          }

        }
    }

}

.product_hr_banner {
    position: relative;
    overflow: hidden;
    img {
        width: 100%;
    }
    .content {
        width:100%;
        position:absolute;
        top:30%;
        left:0;
        .w1440 {
            width:1440px;
            margin:0 auto;
            color:rgb(255, 255, 255);
            h1 {
                font-size:38px;
                font-weight: normal
            }
            .text {
                font-size:18px;
                margin-bottom:44px
            }
            .link {
                border:1px solid #fff;
                text-align:center;
                display:inline-block;
                height:38px;
                line-height:38px;
                width:126px;
                border-radius:5px;
                cursor: pointer;
                /* &:hover::after {
                  content: '>';
                  transition: all 0.4s ease;
                } */
            }
        }
    }
}

.w1440 {
    width:1440px;
    margin:0 auto;
    .active {
      position: fixed;
    }

    .sidebar {
        width:15%;
        display:inline;
        height:300px;
        float: left;
        font-size: 16px;
        ul {
            border-left: 1px solid #e6e6e6;
            li {
                padding: 10px 0;
                padding-left: 30px;
                position: relative;
                margin-bottom: 10px;
                &::before {
                    content: "";
                    width: 3px;
                    height: 0;
                    display: inline;
                    position: absolute;
                    left: -1px;
                    top: 0;
                    background: #0086f9;
                    transition: all 0.4s;
                }
                &:hover::before {
                    content: "";
                    width: 3px;
                    height: 80%;
                    display: block;
                    position: absolute;
                    left: -1px;
                    top: 0;
                    transform: translateY(10%);
                    background: #0086f9;
                    transition: all 0.4s;
                }
                &.active::before {
                    content: "";
                    width: 3px;
                    height: 30px;
                    display: block;
                    position: absolute;
                    left: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #0086f9;
                }
            }
        }
    }

    .list {
        width:80%;
        display:block;
        float: left;
        .title {
          font-size: 20px;
          border-bottom: 1px solid #e0e0e0;
          padding: 10px 0;
          margin-bottom: 2%;
        }
        .subtitle {
          margin-bottom:2%;
          font-size:18px;
          a {
            font-weight:600;
            padding-left:20px;
            position: relative;
            cursor: pointer;
            &::before {
              content: '';
              height: 100%;
              width: 3px;
              background-color: #0086F9;
              position: absolute;
              top: 0;
              left: 0;
            }
            &:hover {
              color: #0086F9;
            }
          }
        }
    }

    .card-box {
        display: inline-block;
        width: 33.3%;
        padding: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        display:inline-block;

        &:hover {
            box-shadow: 0px 0px 8px 8px #E8EEF9;
            border-radius: 5px;
        }

        img {
            display: inline-block;
            vertical-align:top;
            margin-right: 8px;
            margin-top: 5px;
        }

        .main {
            display: inline-block;
            width: 88%;
        }

        .title {
            display: inline-block;
            margin-bottom:10px;
            font-size: 18px;
        }

        .content {
            color: #aaaaaa;
            height: 3em;
            font-size: 14px;
            line-height: 25px;
        }

        .hot {
            display: inline-block;
            background-color: #0086F9;
            color: aliceblue;
            padding: 2px 9px;
            margin-left: 10px;
            border-radius: 3px;
            font-size: 14px;
        }
        .unavail {
            display: inline-block;
            background-color: #8cc9fe;
            color: aliceblue;
            padding: 2px 9px;
            margin-left: 10px;
            border-radius: 3px;
            font-size: 14px;
        }
        .custom {
            display: inline-block;
            background-color: #135191;
            color: aliceblue;
            padding: 2px 9px;
            margin-left: 10px;
            border-radius: 3px;
            font-size: 14px;
        }
    }
}

</style>
