<template>
  <div style="height:100%">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
    name:"App"
}
</script>

<style lang="css">
  
  a {
	text-decoration: none; /* 去除默认的下划线 */
	outline: none;	/* 去除旧版浏览器的点击后的外虚线框 */
	color: #000;	/* 去除默认的颜色和点击后变化的颜色 */ 
}
</style>